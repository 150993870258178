import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { LOGO_ICON_COLOR, LOGO_SLOGAN_COLOR, LOGO_TEXT_COLOR } from '../app/config';
import { useNavigate } from 'react-router';
import { ContainedButton, OutlinedButton } from './MyButtons';
import { Fade, Link } from '@mui/material';

function TopBar() {
  const _pages = [
    { label: 'Home', url: "/home",  },
    { label: 'Courses', url: "/home#courses",},
    { label: 'Schedule', url: "/schedule", },
    { label: 'Calendar', url: "/calendar", },
    // {label:'2023 Summer Camp',url:"/camp2023",icon:<Whatshot sx={{mt:-0.5,ml:-0.5}} fontSize="small"/>,color:LOGO_ICON_COLOR},
    { label: 'About Us', url: "/home#about",},

  ]
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const navigate = useNavigate()
  const [pages, setPages] = React.useState(_pages)
  const [currentPage,setCurrentPage] = React.useState()
  // const initpage = React.useRef(false)
  React.useEffect(() => {
    if (window.location.href.indexOf("#") >= 0) {
      window.location.href = window.location.href.substring(window.location.href.indexOf("#"))
    }
    handleCloseNavMenu()
    // if (!initpage.current) {
    //   initpage.current = true
    //   apis.loadCourses(0,0).then(ret => {
    //     apiResult(ret, data => {
    //       let pp = [...pages]
    //       pp[1].subitem = data
    //       setPages(pp)
    //     }, () => { })
    //   })
    // }
  }, [window.location.href])
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleMenuOption = (e,idx) => {
    let page = pages[idx]
    if(page.subitem) {
      setAnchorEl(e.currentTarget)      
      setCurrentPage(page)
    }else {
      navigate(page.url)
      setCurrentPage()
    }    
  }
  const handleClose = ()=>{
    setCurrentPage()
  }
  const handleCourse =(course)=>{
    setCurrentPage()
    navigate("/course/"+course.id)
  }
  return (
    <AppBar position="sticky" sx={{ bgcolor: '#fff', boxShadow: "0px 0px 1px 0px rgba(0, 0, 0, 0.05),0px 0px 1px 0px rgba(0, 0, 0, 0.05),0px 0px 1px 0px rgba(0, 0, 0, 0.05)" }}>
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} >
            <Link href="/home"><img src="/logo.png" style={{ height: "42px" }} /></Link>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true"
              onClick={handleOpenNavMenu} color="inherit">
              <MenuIcon sx={{ color: LOGO_ICON_COLOR }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page,idx) => (
                <MenuItem key={page.label} onClick={(e) => { handleMenuOption(e,idx) }}>
                  <Typography textAlign="center" sx={{ color: page.color }}>{page.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page, idx) => (
              <Button key={idx} endIcon={page.icon}
                id={"button-" + idx}
                aria-controls={page.open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={page.open ? 'true' : undefined}
                onClick={(e) => handleMenuOption(e,idx)}
                sx={{ display: "inline-flex", my: 0, color: page.color ? page.color : LOGO_TEXT_COLOR }}>
                {page.label}
              </Button>
            ))}
            {currentPage && <Menu
              id="fade-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button',
              }}
              anchorEl={anchorEl}
              open={currentPage?true:false}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              {currentPage?.subitem?.map((item,index)=>{
                  return <MenuItem key={index} onClick={()=>handleCourse(item)}>{item.name}</MenuItem>
              })}
            </Menu>}
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, ml: 2 }}>
            <ContainedButton variant='contained' onClick={() => { window.location.href = "https://client.codefuture.ca" }}>Member Center</ContainedButton>
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' }, mr: 1 }}>
            <img src="logo.png" style={{ height: "30px" }} />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default TopBar;
