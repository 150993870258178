import { Business, Mail, Phone } from "@mui/icons-material"
import { Box,  Container, Grid, Typography } from "@mui/material"
import {  LOGO_SLOGAN_COLOR,  } from "../app/config"
import {  NavigateButton } from "./MyButtons"

const Tail = () => {
    return <Box sx={{ padding: 2, paddingBottom: 4, paddingTop: 6,bgcolor:LOGO_SLOGAN_COLOR }}>
        <Container maxWidth="lg">
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Box sx={{ flexDirection: "column", display: 'flex', alignItems: "left" }}>
                        <Typography variant="h3" sx={{ color: '#fff' }}><img src="/icon.png" width="40px" /> LinkFuture</Typography>
                        <Typography variant="h6" sx={{ color: "#fff", mt: 2 }}><Mail fontSize="small" /> <a href="mailto:info@linkfuture.ca" style={{ color: "#fff" }}>info@linkfuture.ca</a></Typography>
                        <Typography variant="h6" sx={{ color: "#fff", mt: 1 }}><Phone fontSize="small" /> <a href="tel:6472898503" style={{ color: "#fff" }}>(647) 289-8503 (EN)</a></Typography>
                        <Typography variant="h6" sx={{ color: "#fff", mt: 1 }}><Phone fontSize="small" /> <a href="tel:4167259206" style={{ color: "#fff" }}>(416) 725-9206 (中)</a></Typography>
                        <Typography variant="h6" sx={{ color: "#fff", mt: 1 }}><Business fontSize="small" />  <a href="https://maps.app.goo.gl/cGmQxgTkdvefh6FGA" style={{ color: "#fff" }} target="_blank">Unit 201, 35 The Links Road, North York, ON. M2P 1T7</a></Typography>

                    </Box>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Box sx={{ flexDirection: "column", display: 'flex', alignItems: "left", mt: 12 }}>
                        <Typography variant="body2" sx={{ color: "#fff" }}>WeChat: </Typography>
                        <Box sx={{ mt: 0 }}>
                            <img src="/leowechat.jpg" width="100px" />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box sx={{ mt:1,flexDirection: "column", display: 'flex', alignItems: "left" }}>
                        <Typography variant="h4" sx={{ color: '#fff' }}>Our Schedule</Typography>
                        <Typography variant="h6" sx={{ color: "#fff", mt: 2 }}><a href="/schedule" style={{color:"#fff"}}>2024 Schedule</a></Typography>
                        <Typography variant="h6" sx={{ color: "#fff", mt: 2 }}><a href="/calendar" style={{color:"#fff"}}>2024 Calendar</a></Typography>
                        {/* <Typography variant="h6" sx={{ color: "#fff", mt: 1 }}><a href="/camp2023" style={{color:"#fff"}}>2023 Summer Camp</a></Typography> */}
                        <NavigateButton variant="contained" url="/schedule" sx={{width: "160px", mt: 4 }} text="Get Started" />
                    </Box>
                </Grid>

            </Grid>
            <Typography variant="body2" color="text.secondary" align="center" sx={{ color: "#fff",mt:6 }}>
                {"LinkFuture"}
                <br></br>
                {'Copyright © '}
                {' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        </Container>
    </Box>
}
export default Tail