import { Box,  Container, Grid, Typography } from "@mui/material"
import { BACKGROUND_COLOR_1,  LOGO_ICON_COLOR,   LOGO_TEXT_COLOR, NORMAL_TEXT_COLOR } from "../../app/config"
import { NavigateButton } from "../../Component/MyButtons"
import { ScheduleNote, ScheduleTable } from "./ScheduleTable"

const IntensiveMath = (props) => {
    const {schedules} = props
    return <Box sx={{ width: "100%", bgcolor: '#fff',pt: {xs:8,md:12},pb:{xs:8,md:12} }}>
        <Container maxWidth="lg" id="mainblock">
            {/* <img src="/math.jpg" width="200px" />                     */}            
            <Grid container>
                <Grid item xs={12} md={5}>
                    <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", }}>                            
                        <Typography variant="h4" component="div" sx={{ color: LOGO_TEXT_COLOR,fontWeight: "bold" }}>
                            Math Intensive Course<br></br>for <font color={LOGO_ICON_COLOR}>Grades 5+</font>
                        </Typography>
                        <Typography variant="h6" component="div" sx={{ color: NORMAL_TEXT_COLOR }}>
                        According to the Ontario Education Syllabus and Singapore Mathematics, classes are recruited by grades, usually lower grades can apply for higher grades
                        </Typography>  
                        <Box sx={{ flexDirection: "row", alignItems: "flex-start", display: "flex", mt: 2 }}>
                            <NavigateButton variant="contained" url="/register#i-math" sx={{width: "160px", mr: 2 }} text="Register" />
                        </Box>                      
                    </Box>
                </Grid>
                <Grid item xs={12} md={7}>
                    <Box sx={{ display: "flex", flexDirection: "row", ml:{xs:0,md:8},mt:{xs:4,md:0}, flexWrap: "wrap", alignItems: "center", }}>  
                        {schedules?.imath?.plans?.map((s,idx)=>{
                            return <ScheduleTable sx={{mt:idx>0?2:0}} showHead bgcolor={BACKGROUND_COLOR_1} key={idx} title={s.category} datas={s.plan} />
                        })}
                        <ScheduleNote note="Class schedule will be updated continuously. (New) indicates a new class, and existing classes will continue according to the current time" />                        
                    </Box>
                </Grid>
            </Grid>
        </Container>
    </Box>
}

export default IntensiveMath