import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Event from '../event/Event';
import './index.css'
import Layout from '../Component/Layout';
import SignUp from '../user/SignUp'
import { LoginRoute } from './AuthRoute'
import ResetPwd from '../user/ResetPwd'
import Terms from '../user/Terms'
import EmailVerify from '../user/EmailVerify';
import Lottery from '../event/Lottery';
import HomePage from '../home/HomePage';
import Schedule from '../home/Schedule';
import Camp2023 from '../home/Camp2023';
import ClassRegister from '../home/ClassRegister';
// import Course from '../home/Course';
import Courses from '../home/Courses';
import CourseRegister from '../home/CourseRegister';
import SchoolCalendar from '../home/SchoolCalendar';
const App = () => {
  const theme = createTheme();
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />} >
              <Route index element={<HomePage />} />
             <Route path="home" element={<HomePage />} />
             <Route path="schedules" element={<Schedule />} />
             {/* <Route path="course/:id" element={<Course />} /> */}
             <Route path="schedule" element={<Courses />} />   
             <Route path="category" element={<Schedule />} />          
             {/* <Route path="calendar" element={<SchoolCalendar />} />           */}
             <Route path="camp2023" element={<Camp2023 />} />
             <Route path="register" element={<CourseRegister />} />
             <Route path="apply" element={<ClassRegister />} />
             <Route path="calendar" element={<SchoolCalendar />} />   
          </Route>
          
          {/* <Route path="/" element={<Navigate to="/home/index"/>} />
          <Route path="/home" element={<HomeLayout />} >
            <Route path="index" element={<Home />} />
            <Route path="about" element={<Aboutus />} />
            <Route path="events" element={<Events />} />
            <Route path="schedule" element={<Schedule />} />
            <Route path="gallery" element={<Gallery />} />            
            <Route path="qa" element={<Qa />} />
          </Route>
          <Route path="/coach/:name" element={<Coach />} /> */}
          <Route path="/user" element={<Layout />}>
            <Route path="signup" element={<SignUp />} />
            <Route path="signin" element={<LoginRoute />} />
            <Route path="resetpwd" element={<ResetPwd />} />
            <Route path="terms" element={<Terms />} />
            <Route path="emailverify" element={<EmailVerify />} />
          </Route>
          <Route path="/event/:code" element={<Event />} />
          <Route path="/lottery/:code" element={<Lottery />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
