import { Box, Container, Grid, Typography } from "@mui/material"

import { LOGO_ICON_COLOR, LOGO_SLOGAN_COLOR, LOGO_TEXT_COLOR, NORMAL_TEXT_COLOR } from "../app/config"
import { ContainedButton } from "../Component/MyButtons"
import CampForm from "./fragement/CampForm"
const InfoItem = (props) => {
    const { title, value, titleColor, } = props
    return <Typography variant="h6" sx={{ color: NORMAL_TEXT_COLOR }}>
        <font color={titleColor ? titleColor : LOGO_TEXT_COLOR}>{title}:</font>&nbsp;&nbsp;&nbsp;{value}
    </Typography>
}
const InfoItem1 = (props) => {
    const { title, value, titleColor, } = props
    return <Typography variant="h6" sx={{ color: NORMAL_TEXT_COLOR }}>
        <font color={titleColor ? titleColor : LOGO_TEXT_COLOR}>{title}:</font><br></br>{value}
    </Typography>
}

const InfoTitle = (props) => {
    const { title } = props
    return <Typography variant="h6" sx={{ mt: 2, mb: 1, color: LOGO_ICON_COLOR }}>
        {title}
    </Typography>
}
const ItemInfo = (props) => {
    const { pic, title, description } = props
    return <Box sx={{ ...props.sx, flexDirection: "column", alignItems: "center" }}>
        <Box sx={{}}><img src={pic} style={{ width: "100%" }}></img></Box>
        <Typography variant="h6" sx={{ color: LOGO_ICON_COLOR }}>{title}</Typography>
        <Typography variant="body1" sx={{ color: NORMAL_TEXT_COLOR }}>{description}</Typography>
    </Box>
}
const Camp2023 = () => {
    return (<Container maxWidth="lg">
        <Box sx={{ display: "flex", mt: { xs: 2, md: 8 }, mb: 8, flexDirection: "column", width: "100%", flexWrap: "wrap", }}>

            <Box sx={{ zIndex: 100, display: { xs: 'none', md: 'block' }, pl: 20 }}>
                <Typography variant="h2" component="div" sx={{ width: "100%", color: LOGO_ICON_COLOR, mt: 2, fontWeight: "bold" }}>
                    2023 Summer Camp
                </Typography>
                <Typography variant="h4" component="div" sx={{ width: "100%", color: LOGO_SLOGAN_COLOR, mt: 1, }}>
                    July 3 - July 28 Wonderful 4 Weeks
                </Typography>
                <Typography variant="h4" component="div" sx={{ width: "100%", color: LOGO_SLOGAN_COLOR, mt: 1, }}>
                    <font color={LOGO_ICON_COLOR}>5% Off</font> until April 30
                </Typography>
                <ContainedButton onClick={() => { window.location.href = "#register" }} sx={{ width: "160px", mt: 2 }} variant="contained">Register</ContainedButton>

            </Box>
            <Box sx={{ width: "100%", display: { xs: 'block', md: 'none' } }}><img src="/summer.jpeg" width="100%" /></Box>
            <Box sx={{ zIndex: 100, display: { xs: 'block', md: 'none' } }}>

                <Typography variant="h4" component="div" sx={{ width: "100%", color: LOGO_ICON_COLOR, mt: 2, fontWeight: "bold" }}>
                    2023 Summer Camp
                </Typography>
                <Typography variant="h6" component="div" sx={{ width: "100%", color: LOGO_SLOGAN_COLOR, mt: 1, }}>
                    July 3 - July 28 Wonderful 4 Weeks
                </Typography>
                <Typography variant="h6" component="div" sx={{ width: "100%", color: LOGO_SLOGAN_COLOR, mt: 1, }}>
                    <font color={LOGO_ICON_COLOR}>5% Off</font> before April 30
                </Typography>
                <ContainedButton onClick={() => { window.location.href = "#register" }} sx={{ width: "160px", mt: 2 }} variant="contained">Register</ContainedButton>
            </Box>
            <Box sx={{ width: "100%", mt: -38, display: { md: "block", xs: 'none' } }}><img src="/summer.jpeg" width="100%" /></Box>
            <Grid container sx={{ mt: 4 }}>
                <Grid item xs={12} md={8}>
                    <Typography variant="h4" sx={{ display: { xs: 'none', md: 'block' }, color: LOGO_TEXT_COLOR }}>
                        2023 Summer Camp
                    </Typography>
                    <InfoTitle title="Special Items" />
                    <Grid container spacing={2} sx={{ pr: {md:4,xs:0} }}>
                        <Grid item xs={12} md={6}>
                            <ItemInfo
                                title="Magic Math"
                                description="One math topic lecture per day, discover the charm of mathematics. 15 minutes daily quick math practice, improves calculation ability."
                                pic="/math.png" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ItemInfo
                                title="Fun Coding"
                                description="Programming drawing, interactive games, hardware recognition and robot tank, with different learning topics every week."
                                pic="/coding.png"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}><ItemInfo
                            title="Deep Reading"
                            description="Read a classic book deeply every week, share experiences, helps children improve their concentration and depth of thinking"
                            pic="/reading.png"
                        />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ItemInfo
                                title="Outdoor Sports"
                                description="Learn martial arts with a Canadian martial arts champion. Various sports in the park, football, Frisbee, sandbags, paper airplanes, etc."
                                pic="/sports.png"
                            />
                        </Grid>
                    </Grid>
                    <InfoTitle title="Notes" />
                    <InfoItem title="Lunch" value="Lunch is not provided, meal ordering service is provided at your own expense" />
                    <InfoItem title="Snack" value="Provide water, healthy drinks, fresh fruit and healthy snacks" />
                    <InfoItem title="Others" value="If your child has allergies, please inform us in advance" />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="h4" sx={{ mt: { xs: 2, md: 0 }, color: LOGO_TEXT_COLOR }}>
                        Schedule
                    </Typography>
                    <Typography variant="h6" sx={{ color: LOGO_ICON_COLOR, mt: 2, fontWeight: 'bold' }}>
                        Only for Grades 2 - 5
                    </Typography>
                    <InfoItem title="Date" value="July 3 - July 28 (Monday - Friday)" />
                    <InfoItem title="Time" value="9:00 - 16:00" />
                    <InfoItem title="Pickup" value="16:00-16:30" />
                    <InfoTitle title="Daily Schedule" />
                    <InfoItem title="09:30 - 10:30" value="Magic Math" />
                    <InfoItem title="10:30 - 11:00" value="Puzzle and Craft Games" />
                    <InfoItem title="11:00 - 12:00" value="Deep Reading" />
                    <InfoItem title="12:00 - 13:00" value="Lunch & Free Time" />
                    <InfoItem title="13:00 - 14:30" value="Fun Coding" />
                    <InfoItem title="14:30 - 15:00" value="Puzzle and Craft Games" />
                    <InfoItem title="15:00 - 16:00" value="Outdoor Sports" />
                    <InfoItem title="16:00 - 16:30" value="Pickup kids" />
                    <InfoTitle title="Price" />
                    <InfoItem title="Full Day" value="CAD $100/day, $400/week, $720/2 weeks, $1050/3 weeks, $1360/4 weeks, lunch(optional) $15/day" />
                    <InfoItem title="Half Day(9:00-12:00, 13:00-16:00)" value="CAD $60/day, $280/week, $520/2 weeks, $750/3 weeks, $960/4 weeks" />

                </Grid>
            </Grid>
            <Box id="register" sx={{ pt: 10 }}>
                <Typography variant="h4" sx={{ color: LOGO_ICON_COLOR }}>
                    Application
                </Typography>
                <CampForm />
            </Box>
        </Box>
    </Container>)
}
export default Camp2023