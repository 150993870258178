import { PsychologyOutlined, PeopleOutlined, AutoFixHighOutlined } from "@mui/icons-material"
import { Avatar, Box, Container, Grid, Typography } from "@mui/material"
import { LOGO_ICON_COLOR,LOGO_TEXT_COLOR,NORMAL_TEXT_COLOR } from "../../app/config"

const FeatureItem = (props) => {
    const { icon, title, description } = props
    return <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column", alignItems: 'center', m: 2 }}>
        <Avatar sx={{ bgcolor: LOGO_ICON_COLOR, color: "#fff", width: "60px", height: "60px" }}>{icon}</Avatar>
        <Typography variant="h6" sx={{ color: LOGO_TEXT_COLOR, mt: 2 }}>{title}</Typography>
        <Typography variant="body1" sx={{ mt: 2, color: NORMAL_TEXT_COLOR, width: "100%" }} align="center" component={"div"}>{description}</Typography>
    </Box>
}

const Feature = (props) => {
    const items = [
        { icon: <AutoFixHighOutlined />, title: "Select Courses", description: "We collaborate with the best institutions and teachers to offer the courses we excel in." },
        { icon: <PeopleOutlined />, title: "Small classes", description: "Most courses are taught in small group classes with typically 4 to 6 students, ensuring quality." },
        { icon: <PsychologyOutlined />, title: "Experienced teachers", description: "All teachers are experienced and highly professional." },              
    ]
    return (
        <Box sx={{ width: "100%", bgcolor: "rgb(247, 249, 252)" }}>
            <Container maxWidth="lg" id="feature">
                <a href="#feature" />
                <Box sx={{ flexDirection: "column", display: 'flex', alignItems: "center", pt: 8 }}>
                    <Typography variant="body1" align="center" sx={{ color: NORMAL_TEXT_COLOR }}>MAIN FEATURES</Typography>
                    <Typography variant="h3" align="center" sx={{ color: LOGO_TEXT_COLOR, fontWeight: "bold", mt: 2 }}>We only offer high quality courses.</Typography>                    
                </Box>
                <Grid container sx={{ pt: 4, pb: 8 }}>
                    {items.map((item,idx) => (
                        <Grid item xs={12} key={idx} md={4}>
                            <FeatureItem icon={item.icon} title={item.title} description={item.description} key={item.title} />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    )
}
export default Feature