
import {Box} from "@mui/material"
import { useEffect } from "react"
import { Outlet, useNavigate } from "react-router"
import { LOGO_SLOGAN_COLOR } from "../app/config"
import { getUserSession } from "../Utils/Common"
import Tail from "./Tail"
import TopBar from "./TopBar"

const Layout = () => {
    useNavigate()
    const session = getUserSession()
    
    useEffect(()=>{
        if (session && session.email_verified == 0 && window.location.pathname.substring(0, 5) != '/user') {
            window.location.href = '/user/emailverify'
            return <></>
        }else if(window.location.href.indexOf("#") < 0) {
            window.scrollTo(0,0)
        }        
    },[window.location.href])
    return <Box>
        <TopBar/>        
        <Box  sx={{minHeight:"100px"}}><Outlet sx={{minHeight:"400px"}}/></Box>
        <Tail />
    </Box>
}

export default Layout