import { Add, Remove } from "@mui/icons-material"
import { Alert, Box, Grid, IconButton, Paper, TextField, Typography } from "@mui/material"
import { useState } from "react"
import apis from "../../api"
import { LOGO_ICON_COLOR, LOGO_TEXT_COLOR } from "../../app/config"
import { MultiSelector, Selection, SingleSelector } from "../../Component/MuiEx"
import { ContainedButton } from "../../Component/MyButtons"
import { apiResult } from "../../Utils/Common"


const CampForm = () => {
    const [error, setError] = useState()
    const [hint, setHint] = useState()
    const [kids, setKids] = useState([{ classes: [13] }])
    const [fields, setFields] = useState({})
    const [changed, setChanged] = useState(false)
    const handleKidChange = (idx, name, value) => {
        setChanged(true)
        let kk = [...kids]
        kk[idx][name] = value
        setKids(kk)
    }
    const handleChange = (name, value) => {
        setChanged(true)
        setFields({ ...fields, [name]: value })
    }
    const handleSubmit = (e) => {
        setError()
        setHint()
        let data = { ...fields, kids: kids, code: "202304-2" }
        apis.postClassApplicant(data).then(ret => {
            apiResult(ret, data => {
                setChanged(false)
                setHint("Submitted successfully, please complete the payment in time to reserve the spot")
                window.alert("Submitted successfully, please complete the payment in time to reserve the spot")
            }, setError)
        })
        e.preventDefault();

    }
    return <Box id="form">
        <Box component="form" onSubmit={(e) => handleSubmit(e)} sx={{ mt: 1 }}>
            <Paper sx={{ p: 2, mb: 2 }}>
                <Typography variant="body1" sx={{ color: LOGO_TEXT_COLOR }}>Parent Information</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <TextField margin="normal" required fullWidth onChange={(e) => { handleChange("name", e.target.value) }} label="Parent name" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField margin="normal" required fullWidth onChange={(e) => { handleChange("email", e.target.value) }} label="Email Address" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField margin="normal" required fullWidth onChange={(e) => { handleChange("phone", e.target.value) }} label="Phonenumber" />
                    </Grid>
                </Grid>
            </Paper>
            {kids.map((kid, idx) => {
                return <Paper sx={{ p: 2, mb: 2 }} key={idx}>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography variant="body1" sx={{ color: LOGO_TEXT_COLOR, flex: '1 1 10%' }}>Child {idx + 1}</Typography>
                        {kids.length > 1 && <IconButton onClick={() => { let kk = [...kids]; kk.splice(idx, 1); setKids(kk) }} size="small" sx={{ mt: -1.5 }}><Remove /></IconButton>}
                        {idx == kids.length - 1 && <IconButton onClick={() => { let kk = [...kids]; kk.push({ classes: [13] }); setKids(kk) }} size="small" sx={{ mt: -1.5 }}><Add /></IconButton>}
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <TextField margin="normal" required fullWidth id="kid1" label="Your child's name" onChange={(e) => { handleKidChange(idx, "name", e.target.value) }} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Selection required sx={{ width: "100%", mt: { md: 2, xs: 0 } }} label="Select Gender" onChange={(n, v) => handleKidChange(idx, "gender", v)} items={["Boy", "Girl", "Other"]} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField margin="normal" required fullWidth id="kid1" label="Your child's school" onChange={(e) => { handleKidChange(idx, "school", e.target.value) }} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Selection required sx={{ width: "100%", mt: { md: 2, xs: 0 } }} label="Select Grade" onChange={(n, v) => handleKidChange(idx, "grade", v)} items={["Grade 2", "Grade 3", "Grade 4", "Grade 5"]} values={[2, 3, 4, 5]} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SingleSelector onChange={(n, v) => handleKidChange(idx, "times", v)} items={["Full Day", "Morning", "Afternoon"]} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <MultiSelector values={[1, 2, 3, 4]} onChange={(n, v) => handleKidChange(idx, "dates", v ? v.join(',') : null)} items={["7/3-7", "7/10-14", "7/17-21", "7/24-28"]} />
                        </Grid>
                    </Grid>
                </Paper>
            })}
            <Paper sx={{ p: 2 }}>
                <Typography variant="h6">Important Notes</Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>1. If the camp schedule is adjusted, we will communicate with you in time.</Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>2. Registration is complete after payment, you can pay by cash or EMT (<font color={LOGO_ICON_COLOR}>admin@codefuture.ca</font>).</Typography>
                <Typography variant="body1" sx={{ mt: 1 }} >3. Early Bird discount: <font color={LOGO_ICON_COLOR}>5%</font> off until April 30.</Typography>
                <Typography variant="body1" sx={{ mt: 1 }} >4. If a cancellation is required, you may request a refund.</Typography>
            </Paper>
            <Alert severity="info" sx={{ mt: 2 }}>If you enter the same applicant information, you can modify or cancel the previously submitted application</Alert>
            {error && <Alert severity="error" onClose={() => setError()} sx={{ mt: 2 }}>{error}</Alert>}
            {hint && <Alert severity="success" onClose={() => setHint()} sx={{ mt: 2 }}>{hint}</Alert>}
            <ContainedButton type="submit" disabled={!changed} variant="contained" sx={{ mt: 3, width: "160px", mb: 2 }} > Submit </ContainedButton>
        </Box>
    </Box>
}

export default CampForm