import { CalculateOutlined, Code, LanguageOutlined } from "@mui/icons-material"
import { Avatar, Box, Card, CardContent, CardHeader, Grid,Container, Typography } from "@mui/material"
import { LOGO_ICON_COLOR,LOGO_TEXT_COLOR,NORMAL_TEXT_COLOR } from "../../app/config"

const CourseCard = (props) => {
    const { icon, title, description } = props
    return <Card sx={{ ml:2,mr:2,mb:4 }}>
        <CardHeader avatar={<Avatar sx={{ bgcolor: "rgba(245, 124, 0, 0.1)" ,color:LOGO_ICON_COLOR}} aria-label="recipe">{icon}</Avatar>} title={title}/>
        <CardContent>
        <Typography variant="body1" color="text.secondary">{description}</Typography>
      </CardContent>
    </Card>
}
const CourseItem = (props) => {
    const { icon, title, description } = props
    return <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column", alignItems: 'center', m: 2 }}>
        <Avatar sx={{ bgcolor: "rgba(245, 124, 0, 0.1)", color: LOGO_ICON_COLOR, width: "60px", height: "60px" }}>{icon}</Avatar>
        <Typography variant="h6" sx={{ color: LOGO_TEXT_COLOR, mt: 2 }}>{title}</Typography>
        {/* <Typography variant="body1" sx={{ mt: 2, color: NORMAL_TEXT_COLOR, width: "100%" }} align="center" component={"div"}>{description}</Typography> */}
    </Box>
}
const CourseDetail = (props) => {
    const courses = [
        { icon: <Code />, title: "Programming for Children (Grads 3+)", description: "A series of programming courses specially developed for children to help them systematically master programming skills and improve logical thinking ability" },
        { icon: <CalculateOutlined />, title: "Math Intensive Course (Grades 5+)", description: "Combining the Ontario education syllabus with Singapore mathematics and competition mathematics to help children strengthen their mathematics foundation" },
        { icon: <CalculateOutlined />, title: "Competition Math (Grades 5+)", description: "Top competition teachers with many years of experience bring high-quality competition training to children and help them meet challenges" },
        { icon: <LanguageOutlined />, title: "Public Speaking (Grades 3+)", description: "Trains children to exercise logical organization and language expression, and helps them improve their self-confidence." },
        { icon: <LanguageOutlined />, title: "Debate (Grades 6+)", description: "Promotes the exchange of ideas, fosters critical thinking, enhances children's communication and interpersonal skills." },
        { icon: <LanguageOutlined />, title: "English Reading & Writing", description: "Helping children improve the quality of their reading can also enhance their writing and communication skills." },
       
    ]
    const categorys = [
        { icon: <Code />, title: "Computer Programming", description: "" },
        { icon: <CalculateOutlined />, title: "Mathematics", description: "" },
        { icon: <LanguageOutlined />, title: "Language", description: "" },
    ]
    return (
        <Box sx={{ width: "100%", bgcolor: '#fff' }}  id="courses">
            <Container maxWidth="lg" >
                <Box sx={{ flexDirection: "column", display: 'flex', alignItems:"center",pt: 8}}>
                    <Typography variant="body1" align="center" sx={{ color: NORMAL_TEXT_COLOR }}>COURSES</Typography>
                    <Typography variant="h3" align="center" sx={{ color: LOGO_TEXT_COLOR, fontWeight: "bold", mt: 2 }}>Small group in-person classes.</Typography>
                    <Typography variant="h6" align="center" sx={{ color: NORMAL_TEXT_COLOR, mt: 2, ml: 8, mr: 8 }}>To ensure quality, all our courses are taught in person, usually no more than 6 students in a class.</Typography>
                </Box>
                <Grid container sx={{mt: 4,}}>
                    {categorys.map((item,idx) => (
                        <Grid key={idx} item xs={12} md={4}>
                            <CourseItem icon={item.icon} title={item.title} description={item.description} key={item.title} />
                        </Grid>
                    ))}
                </Grid>
                <Box sx={{ flexDirection: "column", display: 'flex', alignItems:"center",}}>
                    <Box sx={{width:{md:"50%",xs:"80%"}}}><img src="/course.png" style={{width:"100%"}} /></Box>
                    <Typography variant="h3" align="center" sx={{ color: LOGO_TEXT_COLOR, fontWeight: "bold", mt: 2 }}>Selected high-quality courses</Typography>
                    
                </Box>
                <Grid container sx={{ mt:4,pb: 8 }}>
                    {courses.map((item,idx) => (
                        <Grid key={idx} item xs={12} md={4}>
                            <CourseCard icon={item.icon} title={item.title} description={item.description} key={item.title} />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    )
}
export default CourseDetail