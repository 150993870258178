import { Box, Container, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Stack, Toolbar, Tooltip, Typography } from "@mui/material"
// import MyCalendar from "../Component/MyCalendar";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import multiMonthPlugin from '@fullcalendar/multimonth'
import './css/SchoolCalendar.css'
import { useEffect, useRef, useState } from "react";
import apis from "../api";
import { apiResult, getDates } from "../Utils/Common";
import { LOGO_ICON_COLOR, LOGO_TEXT_COLOR } from "../app/config";

// function renderEventContent(eventInfo) {
//     console.log(eventInfo.event)
//     return <div>
//         {eventInfo.event.title && <p>{eventInfo.event.title}</p>}
//         {eventInfo.event.url && <img width="50px" height="50px" src={"/"+eventInfo.event.url} />}        
//         {/* {eventInfo.event.type == 0 && <p>no break</p>} */}
//         </div>    
// }

const weeks = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
const SchoolCalendar = () => {
    // const [classes, setClasses] = useState()
    const [events, setEvents] = useState()
    const holiday = useRef()
    const classes = useRef()
    const [classlist, setClasslist] = useState()
    const [year, setYear] = useState(new Date().getFullYear())
    const calendarRef = useRef(null)
    const isInHoliday = (date) => {
        //let date1 = new Date(date).toISOString().substring(0, 10)
        if (holiday.current)
            for (let h of holiday.current) {
                if (date >= h.start && date < h.end ) {
                    return h.type
                }
            }
        return -1
    }
    const isInClass = (date) => {
        if (classes.current) {
            for (let course of classes.current) {
                if (course.events && course.events.length > 0) {
                    for (let event of course.events) {
                        if (event.date == date) {
                            return true
                        }
                    }
                }
            }
        }
        return false
    }
    const colors = [LOGO_ICON_COLOR, "#808000", "#FF4500", "#663399", "#4169E1", "#8B4513", "#008080", "#556B2F", "#008B8B"]
    const changeData = (_courseid) => {
        let list = []
        classes.current?.map(course => {
            if (course.id == _courseid || _courseid == 0) {
                if (course.events) {
                    list = list.concat(course.events)
                    return
                } else {
                    course.events = []
                }
                course.class_news.map(cls => {
                    let cc = 0
                    let bdate = new Date(cls.bdate+" 08:00:00")
                    let edate = new Date(cls.edate)
                    while (bdate <= edate && cc < cls.lessons) {                        
                        if (isInHoliday(bdate.toISOString().substring(0,10)) !== 1) {
                            course.events.push({ title: (_courseid == 0 ? (cls.name.split("-")[0] + "-" + cls.level) : cls.level) + "(lesson " + (cc + 1) + " " + cls.times + ")", date: bdate.toISOString().substring(0, 10), color: colors[course.id % colors.length], classNames: ["fc-event-class"] })
                            cc++
                        }                        
                        bdate.setDate(bdate.getDate() + 7)                        
                    }
                })
                // console.log(course.events)
                list = list.concat(course.events)
            }
        })
        setEvents([...holiday.current, ...list])
    }

    useEffect(() => {
        if (!holiday.current) {
            apis.loadSchedule().then(ret => {
                apiResult(ret, data => {
                    let list = []
                    data.holiday.map((item) => {
                        let edate = new Date(item.end)
                        edate.setDate(edate.getDate() + 1)
                        list.push({ title: item.name, start: item.begin, end: edate.toISOString().substring(0, 10), type: item.type, color: item.type == 1 ? "#3CB371" : "#48D1CC", classNames: ["fc-event-holiday"] })
                    })
                    holiday.current = list
                    classes.current = data.courses
                    setClasslist(data.courses)
                    changeData(0)
                }, () => { })
            })
        }
    }, [])
    return <Box sx={{ p: {xs:1,sm:2} }}>
        <Container maxWidth="lg"><Toolbar style={{ paddingLeft: 2 }}>
            <Typography sx={{ flex: '1 1 50%', p: 1 }} variant={"h6"} component="div" >{year} Calendar</Typography>
            <Stack direction={{ xs: "column", md: "row" }}>
                <FormControl sx={{ minWidth: { xs: "120px", md: '100px' }, p: 1 }}>
                    {/* <InputLabel id="demo-simple-select-autowidth-label">Select Year</InputLabel> */}
                    <Select labelId="demo-simple-select-autowidth-label" defaultValue={year}
                        onChange={(e) => {
                            setYear(e.target.value)
                            calendarRef.current.getApi().gotoDate(e.target.value + "-06-01")
                        }}
                    >
                        {[new Date().getFullYear(), new Date().getFullYear() + 1].map((p, idx) => {
                            return <MenuItem key={idx} value={p}>{p}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <FormControl sx={{ minWidth: { xs: '120px', md: '300px' }, p: 1 }}>
                    {/* <InputLabel id="demo-simple-select-autowidth-label1">Select Class</InputLabel> */}
                    <Select labelId="demo-simple-select-autowidth-label1" defaultValue={0} onChange={(e) => { changeData(e.target.value) }}>
                        <MenuItem value={0}>All Courses</MenuItem>
                        {classlist?.map((p, idx) => {
                            return <MenuItem key={idx} value={p.id}>{p.name}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Stack>

        </Toolbar>
        </Container>
        <FullCalendar plugins={[multiMonthPlugin, interactionPlugin,]} initialView="multiMonthYear" editable={true}
            ref={calendarRef}
            height="auto"
            contentHeight={"auto"}
            events={events}
            multiMonthMaxColumns={4}
            headerToolbar={false}
            initialDate={(year) + "-06-01"}
            eventClick={(info) => {
                window.alert(info.event.title)
            }}
            dayCellContent={(dayCellContent) => {
                let date = new Date(dayCellContent.date).toISOString().substring(0, 10)
                let holidaytype = isInHoliday(date)
                if (holidaytype == 1) {
                    return <div style={{ fontWeight: "bold", color: "#3CB371"}}>B-{dayCellContent.dayNumberText}</div>
                } else if (holidaytype == 0) {
                    return <div style={{ fontWeight: "bold", color: "#48D1CC" }}>{dayCellContent.dayNumberText}</div>
                } else if (isInClass(date)) {
                    return <div style={{ color: LOGO_TEXT_COLOR }}>{dayCellContent.dayNumberText}</div>
                } else  {
                    return <div style={{ color: "#8888" }}>{dayCellContent.dayNumberText}</div>
                }
            }}
        // eventContent={(event,eventElement)=>{
        //         // if (event.imageurl) {
        //         //     eventElement.find("div.fc-content").prepend("<img src='/" + event.imageurl +"' width='16' height='16'>");
        //         // }
        //         console.log(event)
        //         if(isInClass(event.date)) {
        //             console.log(event.event.date)
        //             eventElement.style.background = "red";
        //         }else {
        //             return event.event.title
        //         }
        // }}
        // eventContent={renderEventContent}

        />
    </Box>
}

export default SchoolCalendar