import { Button, styled } from "@mui/material";
import { useNavigate } from "react-router";

const ContainedButton = styled(Button)`  
  background-color:#FE633D;
  :hover {
    background-color: #FF744F;    
  }
`;

const OutlinedButton = styled(Button)`  
  border-color:#FE633D;
  color:#FE633D;
  :hover {
    background-color: rgba(245, 124, 0, 0.1); 
    border-color:#FE633D;   
  }
`;

const NavigateButton = (props)=>{
    const {variant,url,text} = props
    const navigate = useNavigate()
    return variant == "contained"?<ContainedButton variant={variant} onClick={()=>{navigate(url)}} sx={props.sx}>{text}</ContainedButton>:
    <OutlinedButton variant={variant} onClick={()=>{navigate(url)}} sx={{width: "160px", mr: 2 }}>{text}</OutlinedButton>
}
export {OutlinedButton,ContainedButton,NavigateButton}